import { graphql } from 'gatsby';

import { PageWrapper } from 'components/Layout/PageWrapper';
import { MainContainer } from 'components/UI/Containers';
import { StyledStructured } from '../../components/StyledStructured';

const LegalPageTemplate = ({ data, pageContext }) => {
  const {
    datoCmsSymmetryLegal: pageData,
  } = data;
  const { seo, title, description } = pageData;
  const { seoTitle, seoDescription, seoImage } = seo || {};

  return (
    <PageWrapper
      pageData={pageContext}
      {...{ seoTitle, seoDescription, seoImage }}
    >
      <MainContainer>
        <h1>{title}</h1>
        <StyledStructured
          data={description}
        />
      </MainContainer>
    </PageWrapper>
  );
};

export default LegalPageTemplate;

export const query = graphql`
  query LegalPageQuery($locale: String!) {
    datoCmsSymmetryLegal(locale: { eq: $locale }) {
      locale
      title
      description {
        value
        links
        blocks
      }
      slug
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
    }
  }
`;
